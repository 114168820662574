import "./Style/SideNavbar.scss";
import React, { useState } from "react";
import { closeSideNavbar } from "../Functions/SidebarFunction";
import { isSelect } from "../Functions/MobileRouteDomActiveFunc";
import { HiOfficeBuilding, HiOutlineStatusOnline } from "react-icons/hi";
import { MdOutlineOfflinePin } from "react-icons/md";
import OA from "../Image/oa.svg";
import { GoHome } from "react-icons/go";
import { GoInfo } from "react-icons/go";
import { GrServices, GrContact, GrAchievement } from "react-icons/gr";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsJournalBookmark } from "react-icons/bs";
import { AiOutlineLogin } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { scrollPosition } from "../Functions/ScrollPosition";

export function SideNavbar(props) {
  const [token, setToken] = useState(localStorage.getItem("token"));

  // const navigate = useNavigate();
  const openDropdown = () => {
    let doc = document;
    let list = doc.querySelectorAll("#sidenav > div > #course");
    list.forEach((element) => {
      if (element.style.display === "flex") {
        element.style.display = "none";
      } else {
        element.style.display = "flex";
      }
    });
  };

  const click = () => {
    closeSideNavbar();
    scrollPosition();
  };
  return (
    <div id="sidenav" className="sideNavbar">
      <div>
        <img src={OA} alt="menu" />
      </div>
      <div>
        <div>
          <GoHome className="sidenav-icons" />
          <NavLink style={isSelect} to="/" onClick={click}>
            HOME
          </NavLink>
        </div>

        <div>
          <GoInfo className="sidenav-icons" />
          <NavLink style={isSelect} to="/aboutus" onClick={click}>
            ABOUT US
          </NavLink>
        </div>

        <div>
          <GrServices className="sidenav-icons" />
          <NavLink style={isSelect} to="/services" onClick={click}>
            SERVICES
          </NavLink>
        </div>

        <div id="mainCourses" onClick={openDropdown}>
          <span>
            <BsJournalBookmark className="sidenav-icons" />
          </span>
          <NavLink
            style={isSelect}
            // to="/onlinecourses"
          >
            <span>COURSES</span>
          </NavLink>
          {/* <span>&#x25BC;</span> */}
        </div>

        <NavLink
          style={isSelect}
          id="course"
          to="/onlinecourses"
          onClick={click}
        >
          <span>
            <HiOutlineStatusOnline />
          </span>
          <span>Online Courses</span>
        </NavLink>
        <NavLink
          style={isSelect}
          id="course"
          to="/offlinecourses"
          onClick={click}
        >
          <span>
            <MdOutlineOfflinePin />
          </span>
          <span>Offline Courses</span>
        </NavLink>

        <div>
          <GrContact className="sidenav-icons" />
          <NavLink style={isSelect} to="/contactus" onClick={click}>
            CONTACT US
          </NavLink>
        </div>

        <div>
          <HiOfficeBuilding className="sidenav-icons" />
          <NavLink
            style={isSelect}
            to="https://candidate.oceanacademy.co.in/"
            target="_blank"
            onClick={click}
          >
            Job Portal
          </NavLink>
        </div>

        <div>
          <GrAchievement className="sidenav-icons" />
          <NavLink
            style={isSelect}
            to="https://placement.oceanacademy.co.in/"
            onClick={click}
          >
            Placement
          </NavLink>
        </div>

        {token ? (
          <div onClick={click}>
            <AiOutlineLogin className="sidenav-icons" />
            <NavLink style={isSelect} to="/dashboard/mycourses/">
              CLASSROOM
            </NavLink>
          </div>
        ) : (
          <div onClick={click}>
            <AiOutlineLogin className="sidenav-icons" />
            <NavLink style={isSelect} to="/login">
              LOGIN
            </NavLink>
          </div>
        )}

        <div className="sidebar-close">
          <IoIosCloseCircleOutline
            className="sidebarIcon"
            onClick={closeSideNavbar}
          />
        </div>
      </div>
    </div>
  );
}
