import React from "react";
import "../styles/question.scss";

export default function Question({
  options,
  questionDescription,
  question,
  selectOption,
  questionIndex,
  isSelected,
  isResult = false,
  answerDesription = true,
  ans,
  answer,
}) {
  // const answerDesription = true;

  return (
    <div className="q-div">
      <h2>
        {questionIndex + 1}. {question}
      </h2>
      <div className="q-content">
        {questionDescription && (
          <pre>
            <h3 className="q-des">{questionDescription}</h3>
          </pre>
        )}
        <div className="option-div">
          {options.map((item, index) => {
            return (
              <span
                className={`option ${item === isSelected && "op-selected"} ${
                  isResult && answer === item && "ans_item"
                }`}
                key={index}
                onClick={() => {
                  if (!isResult) selectOption(questionIndex, item);
                }}
              >
                {item}
              </span>
            );
          })}
        </div>
        {isResult && (
          <>
            <div className="ans">
              {ans ? (
                <span className="c-ans"> Correct : </span>
              ) : isSelected !== undefined ? (
                <span className="w-ans"> Wrong : </span>
              ) : (
                <span className="s-ans">Skiped</span>
              )}
              <span className="s-ans"> {isSelected}</span>
            </div>
            {answerDesription && (
              <pre className="q-des">
                <h3>In python, power operator is x**y i.e. 2**5=32.</h3>
              </pre>
            )}
          </>
        )}
      </div>
    </div>
  );
}
