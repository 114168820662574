import "./pop_alert.scss";
import React from "react";
import { motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { HiUser } from "react-icons/hi";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  MdEmail,
  MdCall,
  MdDescription,
} from "react-icons/md";
import AlertTextField from "./AlertTextField";
import { useState } from "react";
import { courseList } from "./courseList";
import axios from "../../index";
import { useOnKeyPress } from "../KeyDownAction/keydownaction";

export function PopUpAlert({ closeAlert, isSubmit }) {
  useOnKeyPress(() => {
    setCloseAnime(!closeAime);
    setTimeout(() => {
      closeAlert();
    }, 1500);
  }, "Escape");
  const [closeAime, setCloseAnime] = useState(false);
  const [isDropClicked, setIsDropClicked] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    selectedCoruse: "Select Course",
    description: "",
  });
  const [userDataValidator, setUserDataValidator] = useState({
    name: true,
    email: true,
    mobileNumber: true,
  });
  const [selectedCourse, setSelectedCourse] = useState(0);
  function onChangeHandeler(e) {
    const { name, value } = e.target;

    if (name === "mobileNumber") {
      if (/^0?([0-9]*)$/g.test(value)) {
        setUserData({ ...userData, [name]: value });
        setUserDataValidator({ ...userDataValidator, [name]: true });
      }
      return;
    }

    setUserData({ ...userData, [name]: value });
    setUserDataValidator({ ...userDataValidator, [name]: true });
  }
  function validator() {
    let stringPattern = /^[A-Za-z\s]{3,20}$/g;
    let emailPattern = /^([A-Za-z0-9]+\.?[A-Za-z0-9]+)@([a-z]+)\.[a-z]{2,3}$/g;
    let numberPattern = /^[0-9]{10,11}$/g;
    let name = true;
    let email = true;
    let mobileNumber = true;
    if (!numberPattern.test(userData.mobileNumber)) {
      mobileNumber = false;
    }
    if (!emailPattern.test(userData.email)) {
      email = false;
    }
    if (!stringPattern.test(userData.name.trim())) {
      name = false;
    }
    setUserDataValidator({
      name: name,
      email: email,
      mobileNumber: mobileNumber,
    });
    return name && email && mobileNumber;
  }

  function onSubmit() {
    let a = new Date();
    a = new Date(a.getTime() + 1000 * 60 * 60 * 24 * 365);
    if (validator()) {
      axios
        .post("/enquiry", userData)
        .then((res) => {
          document.cookie = "isAlert=1; expires=" + a.toGMTString() + ";";
          isSubmit(true);
          console.log("success..");
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          alert("somthing wrong");
        });
    } else {
      console.log("invalid field");
    }
  }

  const variants = {
    alertVariant: {
      open: {
        y: "-50%",

        transition: { delay: 3, type: "spring", stiffness: 500 },
      },
      close: { y: window.innerHeight, transition: { delay: 0 } },
    },
    overlayVareant: {
      open: {
        opacity: 1,
        transition: { delay: 2, duration: 0.5 },
      },
      close: {
        opacity: 0,
        transition: { delay: 0, duration: 1 },
      },
    },
    optionVariant: {
      open: {
        height: 300,
        transition: { delay: 0, duration: 0.5, type: "spring", stiffness: 500 },
      },
      close: {
        height: 0,
        transition: { delay: 0, duration: 0.2, type: "tween", stiffness: 200 },
      },
    },
  };

  return (
    <motion.div
      className="pop-up-div"
      initial={{ y: window.innerHeight, x: "-50%" }}
      variants={variants.alertVariant}
      animate={!closeAime ? "open" : "close"}
    >
      <motion.div
        className="c-icon"
        whileHover={{ scale: 1.2, rotateZ: 180 }}
        transition={{ duration: 0.2 }}
      >
        <CgClose
          onClick={() => {
            setCloseAnime(!closeAime);
            setTimeout(() => {
              closeAlert();
            }, 1500);
          }}
        />
      </motion.div>
      <img className="a-logo" src="./oa-a.svg" alt="" width={40} />
      <h1>Course Enquiry</h1>
      <div className="text-fields">
        <AlertTextField
          isNotValid={!userDataValidator.name}
          name="name"
          placeholder="Full Name"
          value={userData.name}
          onChange={onChangeHandeler}
          icon={<HiUser />}
        />
        <AlertTextField
          isNotValid={!userDataValidator.email}
          type="email"
          name="email"
          placeholder="Email"
          value={userData.email}
          onChange={onChangeHandeler}
          icon={<MdEmail />}
        />
        <AlertTextField
          isNotValid={!userDataValidator.mobileNumber}
          isMobileNum={true}
          type="text"
          name="mobileNumber"
          placeholder="Mobile Number"
          value={userData.mobileNumber}
          onChange={onChangeHandeler}
          icon={<MdCall />}
        />
        <div className="d-d-d">
          <div
            className="drop-down"
            onClick={() => {
              setIsDropClicked(!isDropClicked);
            }}
          >
            <p>{userData.selectedCoruse}</p>
            <div className="ico">
              <TiArrowSortedDown />
            </div>
          </div>
          <motion.div
            className="options"
            initial={{
              height: 0,
            }}
            variants={variants.optionVariant}
            animate={isDropClicked ? "open" : "close"}
          // transition={{ type: "spring", stiffness: 1 }}
          >
            {courseList.map((course, index) => {
              return (
                <div
                  key={index}
                  className={`option ${index + 1 === selectedCourse && "isSelect"
                    }`}
                  onClick={() => {
                    setSelectedCourse(index + 1);
                    setUserData({ ...userData, selectedCoruse: course });
                    setIsDropClicked(!isDropClicked);
                  }}
                >
                  {course}
                </div>
              );
            })}
          </motion.div>
        </div>

        <AlertTextField
          isNotValid={false}
          type="textarea"
          name="description"
          placeholder="Your Query"
          value={userData.description}
          onChange={onChangeHandeler}
          icon={<MdDescription />}
        />
      </div>

      <button onClick={onSubmit} className="en-btn">
        Submit
      </button>
    </motion.div>
    // <motion.div
    //   className="pop-overlay"
    //   initial={{
    //     opacity: 0,
    //   }}
    //   variants={variants.overlayVareant}
    //   animate={!closeAime ? "open" : "close"}
    // >

    // </motion.div>
  );
}
