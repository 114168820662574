import React from "react";
import "../styles/collectionCard.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux/";
import { getCollection } from "../../../redux/redux_index";

export default function CollectionCard({
  bgColor,
  languageImage,
  collectionId,
  languageName,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className="mcq-collection-card"
      style={{ background: bgColor ? bgColor : "#e2e2e2" }}
      onClick={() => {
        dispatch(getCollection(languageName));
        navigate("/mcq/topics/" + collectionId);
      }}
    >
      <img src={languageImage} alt={languageName} />
    </div>
  );
}
