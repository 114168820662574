import axios from "../../index";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./view_certificate.scss";
import { useState } from "react";
import oaLogo from "./images/oceanacademy.svg";
import ecPng from "./images/e-c.png";
import { useOnKeyPress } from "../KeyDownAction/keydownaction";
export default function FindCertificate() {
  const [studentId, setStudentId] = useState("");
  const navigate = useNavigate();
  const onSubmitRef = useRef();

  function onVerify() {
    console.log(studentId);
    axios
      .get(`/certificate/get/${studentId}`)
      .then((res) => {
        console.log(res.data);
        if (res.data !== null) {
          navigate(`/view/e_certificate/${studentId}`, { state: res.data });
        } else {
          alert("something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function onChangeHandler(e) {
    const { value } = e.target;
    console.log(value);
    setStudentId(value.toUpperCase());
  }

  useOnKeyPress(onVerify, "Enter");

  return (
    <div className="cert-page">
      <div className="cert-appbar">
        <p>OA Certificate Verification Portal</p>
      </div>
      <div className="cert-s-content">
        <div className="cert-s-img">
          <img src={ecPng} alt="" />
        </div>
        <div className="cert-search-div">
          <div className="search-div">
            <div className="ec-title">Enter your certificate ID to verify</div>
            <input
              className="inp"
              placeholder="Ex:OCNST00001"
              onChange={onChangeHandler}
              value={studentId}
            />
            <button className="btn-check" onClick={onVerify}>
              Check Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
