import {
  CHOOSE_ANSWER,
  GET_ALL_TOPICS,
  GET_COLLECTIONS,
  GET_QUESTION,
  SET_RESULT_INFO,
} from "./MCQTypes";

export const getCollection = (mcqCollectionList = "") => {
  return {
    type: GET_COLLECTIONS,
    payload: mcqCollectionList,
  };
};
export const getAllTopics = (topics = "") => {
  return {
    type: GET_ALL_TOPICS,
    payload: topics,
  };
};

export const getQuestion = (questions) => {
  return {
    type: GET_QUESTION,
    payload: questions,
  };
};

export const chooseAnswer = (qIndex, value) => {
  return {
    type: CHOOSE_ANSWER,
    payload: { qIndex, value },
  };
};

export const setResult = (resultInfo) => {
  return {
    type: SET_RESULT_INFO,
    payload: resultInfo,
  };
};
