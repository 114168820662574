import React from "react";
import { AppbarCombo } from "./AppbarCombo/AppbarCombo";
import { Footer } from "./Footer/Footer";

export function PrivacyPolicy() {
  return (
    <>
      <AppbarCombo />
      <main style={{ margin: "50px auto", maxWidth: "1200px", width: "90%", fontSize: "1.5rem" }}>
        <h1>Privacy Policy for Ocean Academy</h1>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Ocean Academy. We are committed to protecting your privacy
          and ensuring that your personal information is handled in a safe and
          responsible manner. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          https://oceanacademy.co.in/ and enrol in our technical courses.
        </p>
        <h3>2. Information We Collect</h3>
        <ul>
          <li>
            <b>Personal Information:</b> When you register for our courses, we
            may collect personal information such as your name, email address,
            phone number, and payment details
          </li>
          <li>
            <b>Course Data:</b> We may collect information related to your
            course participation, including progress, feedback, and any other
            data necessary to facilitate your learning experience.
          </li>
        </ul>
        <h3>3. How We Use Your Information</h3>
        <b>We use your personal information to:</b>
        <ul>
          <li>Process your registration and payment for courses.</li>
          <li>Provide and manage your learning experience.</li>
          <li>
            Communicate with you about course updates, promotions, and other
            relevant information.
          </li>
          <li>
            Improve our website and services through analysis of usage patterns.
          </li>
          <li>
            Ensure compliance with legal obligations and protect against
            fraudulent activities.
          </li>
        </ul>
        <h3>4. Data Sharing and Disclosure</h3>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. We may share your information with:
        </p>
        <ul>
          <li>
            <b>Service Providers:</b> Third parties who assist us in operating
            our website and providing services, subject to confidentiality
            agreements.
          </li>
          <li>
            <b>Legal Requirements:</b> Authorities or legal entities if required
            by law or to protect our rights, privacy, safety, or property.
          </li>
        </ul>
        <h3>5. Data Security</h3>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, use, or disclosure. However, no
          data transmission over the internet or electronic storage is 100%
          secure, and we cannot guarantee absolute security.
        </p>
        <h3>6. Your Rights and Choices</h3>
        <ul>
          <li>
            <b>Access and Correction:</b> You have the right to access and
            correct your personal information. Contact us to update or review
            your data.
          </li>
          <li>
            <b>Deletion:</b> You may request the deletion of your personal
            information, subject to any legal obligations or legitimate business
            needs.
          </li>
        </ul>
        <h3>7. Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website. Your continued use of our services after such changes constitutes your acceptance of the revised policy.</p>

        <h3>8. Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <address>
          Ocean Academy
          No.10,Second Floor,45 Feet Road, Near HDFC Bank, Venkateshwara Nagar,Saram Puducherry - 605013
          oceandocuments@gmail.com
          9342364952
        </address>
      </main>
      <Footer />
    </>
  );
}
