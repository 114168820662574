import {
  CHOOSE_ANSWER,
  GET_ALL_TOPICS,
  GET_COLLECTIONS,
  GET_QUESTION,
  SET_RESULT_INFO,
} from "./MCQTypes";

const initialState = {
  collection: "",
  topic: "",
  questions: [],
  resultInfo: [],
};

const MCQReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTIONS:
      return { ...state, collection: action.payload };
    case GET_ALL_TOPICS:
      return { ...state, topic: action.payload };
    case GET_QUESTION:
      return { ...state, questions: action.payload };
    case CHOOSE_ANSWER:
      return {
        ...state,
        questions: state.questions.map((q, index) => {
          if (index === action.payload.qIndex) {
            return { ...q, selectedOpition: action.payload.value };
          }
          return q;
        }),
      };
    case SET_RESULT_INFO:
      return { ...state, resultInfo: action.payload };
    default:
      return state;
  }
};

export default MCQReducer;
