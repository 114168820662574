import "./Style/MobileSidebarStyle.scss";
import React from "react";
import { VscClose } from "react-icons/vsc";
import { isSelect } from "../../Functions/MobileRouteDomActiveFunc";
import { CloseSidebar } from "../LoggedInFunctions/CloseSidebar";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { logout } from "../LoggedInFunctions/logout";

export function MobileSidebar({ purchasedCourse }) {
  return (
    <aside className="mobileSidebar-Row">
      <div className="mobileSidebar-CloseBtn">
        <VscClose />
      </div>

      <div className="mobileSidebar">
        <div className="mobileSidebar-EnrolledCourses">
          {purchasedCourse.length > 0 && (
            <div className="mobileSidebar-EnrolledCourses-Heading">
              Classes & Schedules
            </div>
          )}

          {purchasedCourse.map((item, key) => {
            return (
              <NavLink
                // style={isSelect}
                key={key}
                className="mobileSidebar-router"
                to={"/dashboard/schedule/" + item.course.courseId}
                onClick={CloseSidebar}
              >
                {item.course.courseName}
              </NavLink>
            );
          })}
        </div>
        <div className="mobileSidebar-Routers">
          <div className="mobileSidebar-Routers-Heading">Screens</div>
          <NavLink
            // style={isSelect}
            className="mobileSidebar-router"
            to="/dashboard/mycourses"
            onClick={CloseSidebar}
          >
            My Courses
          </NavLink>
          <NavLink
            // style={isSelect}
            className="mobileSidebar-router"
            to="/dashboard/allcourses"
            onClick={CloseSidebar}
          >
            All Courses
          </NavLink>
          <NavLink
            // style={isSelect}
            className="mobileSidebar-router"
            to="/dashboard/notification"
            onClick={CloseSidebar}
          >
            Notification
          </NavLink>
          <NavLink
            // style={isSelect}
            className="mobileSidebar-router"
            to="/dashboard/editprofile"
            onClick={CloseSidebar}
          >
            Edit Profile
          </NavLink>
          <NavLink
            // style={isSelect}
            className="mobileSidebar-router"
            to="/dashboard/certificate"
            onClick={CloseSidebar}
          >
            Certification
          </NavLink>
          <NavLink
            // style={isSelect}
            className="mobileSidebar-router"
            to="/dashboard/purchasehistory"
            onClick={CloseSidebar}
          >
            Purchase History
          </NavLink>
        </div>
        <div className="mobileSidebar-Row-Logout">
          <NavLink
            // style={isSelect}
            className="mobileSidebar-Logout"
            to="/"
            onClick={() => {
              CloseSidebar();
              logout();
            }}
          >
            Logout
          </NavLink>
        </div>
      </div>
    </aside>
  );
}
