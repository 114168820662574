import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

export default axios.create({
  baseURL: "https://us-central1-oceanlivereact.cloudfunctions.net/app/",
  // baseURL: "https://oa-live-api.herokuapp.com/",
});

export const jobPortal = axios.create({
  // baseURL: "http://localhost:5000/",
  // baseURL: "http://127.0.0.1:5001/oceanlivereact/us-central1/jp_api/",
  baseURL: "https://us-central1-oceanlivereact.cloudfunctions.net/jp_api",
});

const root = document.getElementById("root");

// Use createRoot instead of ReactDOM.render
const reactRoot = ReactDOM.createRoot(root);

reactRoot.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>,
);
