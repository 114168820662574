import React from "react";
import "../styles/custom_button.scss";
import { motion } from "framer-motion";

export default function CustomButton({ onClick, children }) {
  return (
    <motion.div className="c-btn" onClick={onClick} whileTap={{ scale: 1.1 }}>
      {children}
    </motion.div>
  );
}
