import React from "react";

import { BsWhatsapp } from "react-icons/bs";

export default function WhatsappBtn() {
  const whatsappUrl =
    "https://wa.me/+919342364952?text=Hello%2C%20I%20would%20like%20to%20know%20more%20information%20about%20the%20courses%20that%20you%20offer%2E&app_absent=1";
  return (
    <div className="fab-name">
      <div className="tagname" style={{ maxWidth: 110 }}>
        <span>Whatsapp</span>
      </div>
      <a
        href={whatsappUrl}
        target="blank"
        className="floatingActionButton"
        style={{ background: "#289133" }}
      >
        <BsWhatsapp className="icon" />
      </a>
    </div>
  );
}
