import "./Style/CoursesSidebarStyle.scss";
import React from "react";
import { Link } from "react-router-dom";

export function CoursesSidebar({ purchasedCourse }) {
  return (
    <div className="CoursesSidebar">
      <h2>Classes & Schedules</h2>
      <div>
        {purchasedCourse.map((item, key) => {
          return (
            <>
              <Link
                key={key}
                className="route"
                to={"/dashboard/schedule/" + item.course.courseId}
              >
                {item.course.courseName}
              </Link>
              <div className="line"></div>
            </>
          );
        })}
      </div>
    </div>
  );
}
