import "./Style/ZoomScheduleStyle.scss";
import React, { useEffect, useState } from "react";
import { PageTitlebar } from "./../PageTitlebar/PageTitlebar";
import { OALoaders } from "../../Loaders/OALoader/OALoader";
import { TiTick, TiVideo } from "react-icons/ti";
import axios from "../../../index";
import { useNavigate, useParams } from "react-router-dom";
import { CoursesSidebar } from "../CoursesSidebar/CoursesSidebar";

export function ZoomSchedule() {
  const mL = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const mS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [allSchedule, setAllSchedule] = useState([]);
  const [user, setUser] = useState({});
  const [course, setCourse] = useState([]);
  const [loading, setLoading] = useState(true);
  const param = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [purchasedCourse, setPurchaseedCourse] = useState([]);

  async function getPurchasedCourse() {
    const token = await localStorage.getItem("token");

    await axios
      .post("purchased/", { token: token })
      .then((res) => {
        setLoading(false);
        setPurchaseedCourse(res.data);
      })
      .catch((error) => {
        console.log(error.message);

        navigate("/");
      });
  }

  function isJoin(schedule) {
    let username = sessionStorage.getItem("username");

    // make isJoin dynamicly
    axios
      .post("/schedule/isJoin/", {
        token: token,
        scheduleId: schedule._id,
        isJoin: true,
      })
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        let meetingUrl = schedule.zoomLink + username;
        // meetingUrl.replace(replaceNameRegx, username);
        // console.log(meetingUrl);
        // ! our custom zoom page and redux username
        // navigate("/dashboard/zoompage");
        if (schedule.zoomLink === "") {
          alert("its completed");
        } else {
          window.open(meetingUrl);
          // navigate("/dashboard/zoompage", { state: { zoomLink: meetingUrl } });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  function getAllSchedule() {
    axios
      .post("/schedule/view/" + param.courseId, { token: token })
      .then((res) => {
        console.log(res.data.schedules);
        setAllSchedule(res.data.schedules);
        setUser(res.data.user);
        setCourse(res.data.course);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  useEffect(() => {
    getAllSchedule();
    getPurchasedCourse();
  }, [param.courseId]);

  if (loading) {
    return (
      <section className="loading">
        <OALoaders />
      </section>
    );
  }

  return (
    <>
      <CoursesSidebar purchasedCourse={purchasedCourse} />
      <div className="zoomSchedule">
        <PageTitlebar emoji="" title={"Zoom Schedule"} />
        <div className="zoomSchedule-username">
          Hi &#128522; {user.firstName}, you are enrolled in{" "}
          <span style={{ color: "green", fontWeight: "500" }}>
            {course.courseName}
          </span>{" "}
          course.
        </div>

        <div
          className="zoomSchedule-Btn-Row"
          onClick={() => {
            navigate("/dashboard/mycoursemoreinfo/" + course.courseId);
          }}
        >
          <div className="zoomSchedule-viewSyllabus">View Syllabus</div>
        </div>

        {allSchedule.map((schedule, key) => {
          let currentDate = new Date(Date.now());

          let dateTime = new Date(schedule.classDate);
          let date = dateTime.getDate();
          let month = dateTime.getMonth();
          let classTime = dateTime.getTime();
          let cTime = currentDate.getTime();

          let isCompleted = classTime - cTime < -125706302;
          //let isCompleted = false;
          console.log("0000000000000000");
          console.log(dateTime.getTime() - currentDate.getTime());
          return (
            <>
              {/* Desktop view */}

              <main key={key} className="zoomSchedule-Card">
                <section
                  className="zoomSchedule-Card-Left"
                  style={{ background: isCompleted ? "green" : "#2c99f2" }}
                >
                  <p>{schedule.batchTime} PM</p>
                  <p>{date}</p>
                  <p>{mL[month]}</p>
                </section>

                <section className="zoomSchedule-Card-Right-Row">
                  <div className="zoomSchedule-Card-Right-Div1">
                    <p>{schedule.topic}</p>
                    <p>Zoom Meeting with {schedule.trainer.trainerName}</p>
                    <p>60 Minutes</p>
                  </div>
                  {isCompleted ? (
                    <div className="zoomSchedule-Card-Right-Div2">
                      <TiTick
                        className="zoomSchedule-Card-Right-Div2-icons"
                        style={{ color: "gray" }}
                      />
                      <p style={{ color: "gray" }}>Completed</p>
                    </div>
                  ) : (
                    <div
                      className="zoomSchedule-Card-Right-Div2"
                      onClick={() => {
                        isJoin(schedule);
                      }}
                    >
                      <TiVideo className="zoomSchedule-Card-Right-Div2-icons" />
                      <p>Join</p>
                    </div>
                  )}

                  <div
                    className="zoomSchedule-Card-Right-Div3"
                    style={{ background: isCompleted ? "green" : "#2c99f2" }}
                  ></div>
                </section>
              </main>

              {/* mobile view */}

              {/* <main key={key} className="m-zoomScheduleCard">

              </main> */}
            </>
          );
        })}
      </div>
    </>
  );
}
