import React from "react";
import { useParams } from "react-router-dom";
import axios from "../../index";
import { useSelector, useDispatch } from "react-redux";
import { getAllTopics } from "../../redux/redux_index";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import TopicCard from "./Widgets/TopicCard";
import "./styles/topic_Card.scss";
import { Appbar } from "../Appbar/Appbar";

export default function MCQTopic() {
  const { collectionId } = useParams();
  const [allTopics, setAllTopics] = useState([]);
  const dispatch = useDispatch;

  const navigate = useNavigate();

  function getAllTopics_api() {
    axios
      .post("/mcq/question/topics", {
        collectionId: collectionId,
      })
      .then((res) => {
        setAllTopics(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  function startTest(cId, topic) {
    navigate(`/mcq/test/${cId}/${topic}`);
  }

  useEffect(() => {
    getAllTopics_api();
    return () => console.log("useEffect cleanup");
  }, []);

  return (
    <>
      <div className="ap-br">
        <Appbar />
      </div>
      <div className="all-topic-div">
        {allTopics.map((topic, index) => {
          return (
            <TopicCard
              key={index}
              topic={topic}
              onClick={startTest.bind(this, collectionId, topic)}
            />
          );
        })}
      </div>
    </>
  );
}
