import "./Style/ReviewsStyle.scss";
import React, { useEffect } from "react";
import { reviews } from "./StudentsData";
import Quotes from "./quote-icon.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export function Reviews() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    let num = 0;
    let doc = document;
    let reviewsCard = doc.querySelectorAll(".reviews-Card");
    let reviewsDots = doc.querySelector(".reviews-Dots");
    let circle = doc.querySelector(".circle");

    // console.log(circle, "reviewDots");

    for (let i = 0; i < reviewsCard.length; i++) {
      let circle = doc.createElement("div");
      circle.className = "circle";
      reviewsDots.appendChild(circle);
      console.log(i, circle);
    }

    // console.log(reviewsCard, "reviewsCard");

    let timer = setInterval(() => {
      num++;
      if (num >= reviewsCard.length) {
        num = 0;
      }
      controller(num, reviewsCard);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  let controller = (num, reviewsCard) => {
    const circles = document.querySelectorAll(".circle");
    for (let i = 0; i < reviewsCard.length; i++) {
      if (num === i) {
        reviewsCard[i].style.display = "flex";
        circles[i].style.backgroundColor = "rgb(0, 72, 100)";
      } else {
        reviewsCard[i].style.display = "none";
        circles[i].style.backgroundColor = "transparent";
      }
    }
  };

  return (
    <main className="reviews">
      <p>What our</p>
      <h1>Students are Saying</h1>
      <section
        className="reviews-Row"
        data-aos="zoom-in-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine"
        data-aos-duration="500"
      >
        {reviews.map((data, index) => {
          return (
            <div className="reviews-Card" key={index}>
              <div
                className="image"
                style={{
                  backgroundImage: `url(${data.profile})`,
                  width: "200px",
                }}
              ></div>
              <div className="para">
                <p>
                  <img width={40} src={Quotes} alt="" /> {data.studentReview}
                </p>
                <p>{data.name}</p>
                <p>{data.designation}</p>
              </div>
            </div>
          );
        })}
      </section>
      <section className="reviews-Dots"></section>
    </main>
  );
}
