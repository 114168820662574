import "./Style/LoggedInAppbarStyle.scss";
import React, { useEffect, useState } from "react";
import Logo from "../../Image/login_logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { GrCertificate } from "react-icons/gr";
import { RiProfileLine } from "react-icons/ri";
import { FaShopify } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { GiBookshelf } from "react-icons/gi";
import { FaBookReader } from "react-icons/fa";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { CloseSidebar } from "../LoggedInFunctions/CloseSidebar";
import { logout } from "../LoggedInFunctions/logout";
import axios from "../../../index";

export function LoggedInAppbar() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    occupation: "",
    instOrOrg: "",
    _id: "",
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    companyOrSchool: "",
    state: "",
    country: "",
    mobileNumber: "",
    profilePicture: sessionStorage.getItem("user"),
    skills: [],
  });

  const isSelect = ({ isActive }) => {
    return {
      color: isActive ? "rgba(6, 176, 255, 0.993)" : "rgba(0, 0, 0, 0.747)",
      backgroundColor: isActive ? "White" : "",
      border: isActive ? "2px solid rgb(0, 221, 255)" : "",
    };
  };

  let menuOnClick = () => {
    const doc = window.document;
    const closeMenu = doc.querySelector(".mobileSidebar-CloseBtn");
    const mobileSidebar = doc.querySelector(".mobileSidebar");

    mobileSidebar.style.width = "250px";
    mobileSidebar.style.boxShadow =
      "2px 2px 20px 100000px rgba(0, 0, 0, 0.486)";

    closeMenu.className = "mobileSidebar-CloseBtn-active";

    const closeMenuActive = doc.querySelector(".mobileSidebar-CloseBtn-active");

    closeMenuActive.addEventListener("click", () => {
      mobileSidebar.style.width = "0px";
      mobileSidebar.style.boxShadow = "0px 0px 0px 0px transparent";
      closeMenuActive.className = "mobileSidebar-CloseBtn";
    });
  };

  async function getUserData() {
    const token = localStorage.getItem("token");

    await axios
      .post("user", { token: token })
      .then((res) => {
        setUserInfo(res.data);
        sessionStorage.setItem("user", res.data.profilePicture);
        sessionStorage.setItem("username", res.data.firstName);
      })
      .catch((error) => {
        console.log(error.message);

        navigate("/");
      });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="loggedInAppbar">
      <section
        className="appbarLeft"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Logo} alt="" />
      </section>

      <section className="appbarRight">
        <NavLink
          className="route"
          id="remove"
          to="/dashboard/mycourses"
          style={isSelect}
        >
          My Courses
        </NavLink>

        <NavLink
          className="route"
          id="remove"
          to="/dashboard/allcourses"
          style={isSelect}
        >
          All Courses
        </NavLink>

        <div id="mainNotification">
          <Link to="notification" className="notification-Logo">
            <IoNotificationsOutline className="notification-Icon" />
          </Link>
        </div>
        {/* <div className="spreadCircle"></div> */}

        <div id="loggedInAppbar-Profile">
          <div className="loggedInAppbar-Profile-Circle">
            <img src={userInfo.profilePicture} alt="" />

            <div className="loggedInAppbar-Dropdown">
              <div className="loggedInAppbar-Dropdown-Row">
                <div className="loggedInAppbar-Dropdown-Left-Circle">
                  {userInfo.firstName.substring(0, 1).toUpperCase()}
                </div>
                <div className="loggedInAppbar-Dropdown-Right">
                  <p>{userInfo.firstName + " " + userInfo.lastName}</p>
                  <p>{userInfo.email}</p>
                </div>
              </div>
              <div className="loggedInAppbar-Dropdown-Col">
                <NavLink
                  className="loggedInAppbar-Dropdown-Col-li"
                  to="/dashboard/mycourses"
                >
                  <FaBookReader className="loggedInAppbar-Dropdown-Col-li-icons" />
                  <div className="text-decoration">My Courses</div>
                </NavLink>
                <NavLink
                  className="loggedInAppbar-Dropdown-Col-li"
                  to="/dashboard/allcourses"
                >
                  <GiBookshelf className="loggedInAppbar-Dropdown-Col-li-icons" />
                  <div className="text-decoration">All Courses</div>
                </NavLink>

                <Link
                  className="loggedInAppbar-Dropdown-Col-li"
                  to="/dashboard/notification"
                >
                  <IoNotificationsOutline className="loggedInAppbar-Dropdown-Col-li-icons" />
                  <div className="text-decoration">Notification</div>
                </Link>

                <Link
                  className="loggedInAppbar-Dropdown-Col-li"
                  to="/dashboard/editprofile"
                >
                  <RiProfileLine className="loggedInAppbar-Dropdown-Col-li-icons" />
                  <div className="text-decoration">Edit Profile</div>
                </Link>
                <Link
                  className="loggedInAppbar-Dropdown-Col-li"
                  to="/dashboard/certificate"
                >
                  <GrCertificate className="loggedInAppbar-Dropdown-Col-li-icons" />
                  <div to="/dashboard/certificate" className="text-decoration">
                    Certificate
                  </div>
                </Link>

                <Link
                  className="loggedInAppbar-Dropdown-Col-li"
                  to="/dashboard/purchasehistory"
                >
                  <FaShopify className="loggedInAppbar-Dropdown-Col-li-icons" />
                  <div className="text-decoration">Purchase History</div>
                </Link>
                <div style={{ width: "100%" }} onClick={logout}>
                  <Link className="loggedInAppbar-Dropdown-Col-li" to="/login">
                    <HiOutlineLogout className="loggedInAppbar-Dropdown-Col-li-icons" />
                    <div className="text-decoration">Logout</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="loggedIn-Appbar-Menu">
        <HiOutlineMenuAlt1
          className="loggedIn-Appbar-Menu-icon"
          onClick={menuOnClick}
        />
      </div>
    </div>
  );
}
