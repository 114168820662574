import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../index";
import "./webinar_zoom.scss";

export default function WebinarZoom() {
  const [zoomLink, setZoomLink] = useState("");
  const iframeRef = useRef(null);

  const param = useParams();

  function getWebinarLink() {
    console.log(param.userId);
    axios
      .get("/webinar/user/join/" + param.userId)
      .then((res) => {
        console.log(res.data);
        setZoomLink(res.data.zoomLink);
        // iframeRef.current.style.height = "calc(100vh - 200px)";
        // iframeRef.current.style.width = "calc(100vw - 200px)";
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getWebinarLink();
  });
  return (
    <div className="webinar-zoom">
      {zoomLink && (
        <iframe
          className="webinar-if"
          title="webinar-iframe"
          ref={iframeRef}
          src={zoomLink}
          allow="microphone;"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      )}
    </div>
  );
}
