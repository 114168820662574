import axios from "../../index";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import Question from "./Widgets/Question";

export default function MCQResult() {
  const resultId = useParams().resultId;
  const [result, setResult] = useState({ answerKey: [] });

  function getResult() {
    axios
      .get("mcq/question/result/" + resultId)
      .then((res) => {
        console.log(res.data);
        setResult(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getResult();
  }, []);
  return (
    <div>
      {result.answerKey.map((question, index) => {
        return (
          <Question
            key={index}
            questionIndex={index}
            isResult={true}
            question={question.question}
            options={question.options}
            isSelected={question.selectedOption}
            ans={question.answer === question.selectedOption}
            answer={question.answer}
            questionDescription={question.questionDescription}
          />
        );
        // return <li key={index}>dd{index}</li>;
      })}
    </div>
  );
}
