import "./Style/AllCourses.scss";
import React, { useEffect, useState } from "react";
import axios from "../../../index";
import { MdLockClock } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { OALoaders } from "../../Loaders/OALoader/OALoader";
import { Link } from "react-router-dom";
import { PageTitlebar } from "../PageTitlebar/PageTitlebar";

export function AllCourses() {
  const [allCourse, setAllCourse] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("course/")
      .then((res) => {
        setLoading(false);
        setAllCourse(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const btnHover = (e) => {
    const doc = document;
    const btn = doc.querySelectorAll(".allCoursesBtn");

    for (const i of btn) {
      const x = e.nativeEvent.pageX - i.offsetLeft;
      const y = e.nativeEvent.pageY - i.offsetTop;
      i.style.setProperty("--x", x + "px");
      i.style.setProperty("--y", y + "px");
    }
  };

  return (
    <>
      <PageTitlebar emoji="&#128218;" title="All Courses" />
      {loading === true && (
        <section className="loading">
          <OALoaders />
        </section>
      )}
      <div className="allCourses">
        {allCourse.map((course, index) => {
          return (
            <div key={index} className="onlineCourses-card">
              <img
                className="image"
                src={course.courseImage}
                alt=""
                srcset=""
              />

              <div className="content">
                <p className="courseName">{course.courseName} Course</p>
                <p className="description">{course.description}</p>

                <div>
                  <p>
                    <span>
                      <MdLockClock className="online-icons" />
                    </span>
                    {course.duration} HRS
                  </p>
                  <p>
                    <span>
                      <FaRegCalendarAlt className="online-icons" />
                    </span>
                    17 Nov
                  </p>
                  <p>
                    <span>
                      <MdLockClock className="online-icons" />
                    </span>
                    5:30 PM
                  </p>
                </div>
              </div>
              <Link
                className="allCoursesBtn"
                onMouseMove={btnHover}
                to={"/dashboard/viewdetails/" + course.courseId}
              >
                {/* <div className="btnEffect"></div> */}
                <span>View Details</span>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}
