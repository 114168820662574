import React from "react";
import { HiCreditCard } from "react-icons/hi";
import { BsCardText } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function E_certificateBtn({ onHover }) {
  return (
    <div className="fab-name">
      <div className="tagname" style={{ maxWidth: 160 }}>
        <span>Verify Certificate</span>
      </div>
      <Link
        to="/verify/e_certificate/"
        target="_blank"
        className="floatingActionButton"
      >
        <BsCardText className="icon" />
      </Link>
    </div>
  );
}
