import "./Style/CourseCard.scss";
import React, { useEffect, useState } from "react";
import { OALoaders } from "../../Loaders/OALoader/OALoader";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "../../../index";
import { CoursesSidebar } from "../CoursesSidebar/CoursesSidebar";

export function MyCourseCard() {
  const navigate = useNavigate();
  const [purchasedCourse, setPurchaseedCourse] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getPurchasedCourse() {
    const token = await localStorage.getItem("token");

    await axios
      .post("purchased/", { token: token })
      .then((res) => {
        setLoading(false);
        setPurchaseedCourse(res.data);
      })
      .catch((error) => {
        console.log(error.message);

        navigate("/");
      });
  }
  useEffect(() => {
    getPurchasedCourse();
  }, []);

  if (loading) {
    return (
      <section className="loading">
        <OALoaders />
      </section>
    );
  }

  // const mouseTrack = (e) => {
  //   const doc = window.document;
  //   const btn = doc.querySelector(".myCourse-Btn");
  //   const track = doc.querySelector(".myCourse-Btn > .track");

  //   console.log(track, "track");

  //   // track.top = e.offsetX + "px";
  //   // track.left = e.offsetY + "px";

  //   btn.style.setProperty("--x", e.offsetX + "px");
  //   btn.style.setProperty("--y", e.offsetY + "px");
  // };

  return (
    <main className="row">
      {/* <PageTitlebar emoji="&#128218;" title="All Courses" /> */}
      <CoursesSidebar purchasedCourse={purchasedCourse} />

      <div className="myCourse">
        {purchasedCourse.map((course, index) => {
          console.log(course);
          return (
            <div key={index} className="myCourseCard">
              <div
                style={{
                  backgroundImage: `url(${course.course.courseImage})`,
                }}
              ></div>
              <div className="myCourse-content">
                <p className="courseName">
                  {course.course.courseName} Full Package Course
                </p>
                <p className="description">{course.course.description}</p>
              </div>

              <NavLink
                to={"/dashboard/mycoursemoreinfo/" + course.course.courseId}
                className="myCourse-Btn"
              >
                {/* <div className="track"></div> */}
                <span>More Info</span>
              </NavLink>
            </div>
          );
        })}
      </div>
    </main>
  );
}
