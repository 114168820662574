import React from "react";
import { AppbarCombo } from "./AppbarCombo/AppbarCombo";
import { Footer } from "./Footer/Footer";

export function TermsConditions() {
  return (
    <>
      <AppbarCombo />
      <main
        style={{
          margin: "80px auto",
          maxWidth: "1200px",
          width: "90%",
          fontSize: "1.5rem",
        }}
      >
        <h1>Terms and Conditions for Admission to Ocean Academy</h1>
        <h3>1. Admission Criteria:</h3>
        <p>
          Prospective students must meet the eligibility requirements specified
          by Ocean Academy, including educational qualifications and
          prerequisite knowledge as outlined in the course prospectus.
        </p>
        <h3>2. Application Procedure:</h3>
        <p>
          Applicants are required to complete the admission application form
          accurately and submit it by the specified deadline. Submission of the
          application form constitutes acknowledgment and acceptance of these
          terms and conditions.
        </p>
        <h3>3. Code of Conduct:</h3>
        <p>
          Students are expected to adhere to the code of conduct established by
          Ocean Academy, demonstrating professionalism, respect for others,
          integrity, and ethical behavior at all times. The code of conduct
          encompasses rules related to attendance, punctuality, academic
          integrity, respectful communication, and professional behavior within
          the institute premises and online learning platforms.
        </p>
        <h3>4. Fees and Payment Policies:</h3>
        <p>
          Tuition fees, payment methods, installment plans, and deadlines for
          fee payments are to be strictly maintained as per the instruction of
          administrator. Failure to pay fees by the specified deadlines may
          result in the withholding of academic services or administrative
          penalties.
        </p>
        <h3>5. Attendance Policy:</h3>
        <p>
          Students are required to maintain regular attendance as per the
          institute's attendance policy. Excessive absences without valid
          reasons may result in academic penalties or disciplinary action or
          cancellation of academic services.
        </p>
        <h3>6. Academic Progress and Performance:</h3>
        <p>
          Students are expected to maintain satisfactory academic progress,
          including achieving a minimum grade point as specified by the
          institute. Completion of assignments, participation in class
          activities, and adherence to tasks and assessment deadlines are
          essential for academic success.
        </p>
        <h3>7. Assessment and Evaluation:</h3>
        <p>
          Assessment methods, grading criteria, examination schedules, and
          project deadlines are communicated by course instructors. Students
          have the right to appeal grades or assessment decisions through the
          institute's established procedures.
        </p>
        <h3>8. Use of Resources and Facilities:</h3>
        <p>
          Students are granted access to institute resources and facilities for
          academic purposes only and must adhere to usage guidelines specified
          by the institute.
        </p>
        <h3>9. Technology Policies:</h3>
        <p>
          The use of technology within the institute must comply with acceptable
          use policies, including regulations related to computer usage,
          internet access, software licensing, and electronic device usage.
        </p>
        <h3>10. Anti-Discrimination and Harassment Policies:</h3>
        <p>
          Ocean Academy prohibits discrimination, harassment, bullying, or any
          form of misconduct based on race, gender, religion, nationality,
          disability, sexual orientation, or any other protected characteristic.
        </p>
        <h3>11. Health and Safety Guidelines:</h3>
        <p>
          Students must comply with health and safety regulations, emergency
          procedures, and guidelines for maintaining a safe learning environment
          within the institute premises.
        </p>
        <h3>12. Privacy and Confidentiality:</h3>
        <p>
          Ocean Academy respects student privacy and handles personal
          information in accordance with applicable data protection laws.
          Student records and academic information are treated with
          confidentiality.
        </p>
        <h3>14. Student Grievance Procedure:</h3>
        <p>
          Ocean Academy has established procedures for addressing student
          grievances, complaints, or disputes. Students are encouraged to follow
          these procedures for timely resolution of concerns.
        </p>
        <h3>14. Withdrawal and Discontinuation:</h3>
        <p>
          Students may withdraw from courses or programs following the
          institute's withdrawal procedures. Discontinuation is considered as
          personal choice and students once enrolled and paid fees cannot claim
          for refund.
        </p>
        <h3>15. Intellectual Property Rights:</h3>
        <p>
          Students cannot retain ownership of intellectual property developed
          independently during their course of study or internship. However,
          students must adhere to licensing agreements and respect the
          institute's intellectual property rights.
        </p>
        <h3>16. Professionalism and Career Development:</h3>
        <p>
          Ocean Academy provides support for students' professional development
          on request of individuals willingness, including career guidance,
          networking opportunities, internships, and job placement assistance.
        </p>
        <h3>17. No Refund Policy:</h3>
        <p>Ocean Academy maintains a strict no-refund policy.
          Once the tuition fees have been paid, they are non-refundable under any
          circumstances, including but not limited to withdrawal, discontinuation,
          or expulsion.</p>
      </main>
      <Footer />
    </>
  );
}
