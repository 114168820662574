export function CloseSidebar() {
  const doc = window.document;
  const closeMenuActivedoc = doc.querySelector(
    ".mobileSidebar-CloseBtn-active"
  );
  const mobileSidebar = doc.querySelector(".mobileSidebar");

  mobileSidebar.style.width = "0px";
  closeMenuActivedoc.className = "mobileSidebar-CloseBtn";

  console.log("sidebar closed");
}
