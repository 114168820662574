import React from "react";
import "./404.scss";

export default function PageNotFound() {

  return (
    <div className="page-not-found-main-div">
      <h1>404 Page Not Found</h1>
    </div>
  );
}
