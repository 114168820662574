import "./Style/LoggedInLandingPageStyle.scss";
import React, { useState, useEffect } from "react";
import { LoggedInAppbar } from "../LoggedInAppbar/LoggedInAppbar";
import { MobileSidebar } from "./../MobileSidebar/MobileSidebar";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "../../../index";

export function LoggedInLandingPage() {
  const navigate = useNavigate();
  const [purchasedCourse, setPurchaseedCourse] = useState([]);

  async function getPurchasedCourse() {
    const token = await localStorage.getItem("token");

    await axios
      .post("purchased/", { token: token })
      .then((res) => {
        setPurchaseedCourse(res.data);
      })
      .catch((error) => {
        console.log(error.message);
        console.log("first");
        navigate("/");
      });
  }

  useEffect(() => {
    let session = localStorage.getItem("token");
    getPurchasedCourse();
    if (session === null || session === "undefined") {
      navigate("/login");
    }
  }, []);

  const state = () => {
    console.log(1);
  };

  // const customCursor = (e) => {
  //   const doc = window.document;
  //   const cursor = doc.querySelector(".cursor");
  //   const dot = doc.querySelector(".dot");

  //   // const afterLogin = doc.querySelector(".afterLogin");
  //   // const body = doc.querySelector("body");

  //   // console.log(body.clientHeight, "body.clientHeight");

  //   // console.log(afterLogin.cl);

  //   cursor.style.left = `${e.clientX}px`;
  //   cursor.style.top = `${e.pageY}px`;
  //   dot.style.left = `${e.clientX}px`;
  //   dot.style.top = `${e.pageY}px`;

  //   // console.log(e.clientX, "x");
  //   console.log(e.pageY, "y");
  //   console.log(e, "e");
  // };

  return (
    <main className="afterLogin">
      {/* <div className="cursor"></div>
      <div className="dot"></div> */}

      <LoggedInAppbar state={state} />
      <MobileSidebar purchasedCourse={purchasedCourse} />
      <div className="dashboard">
        <Outlet />
      </div>
    </main>
  );
}
