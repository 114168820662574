import React from "react";
import "./Style/PageTitlebarStyle.scss";

export function PageTitlebar(props) {
  return (
    <div className="pageTitlebar">
      <p>
        {props.emoji} {props.title}
      </p>
    </div>
  );
}
