import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import "./view_certificate.scss";
import oalogo from "./images/oceanacademy.svg";
import { useRef } from "react";
export default function ViewCertifivcate() {
  const state = useLocation().state;
  const navigate = useNavigate();
  const certRef = useRef();
  function onCertDowload() {
    const myWind = window.open("OA Certificate Portal", "Download Certificate");
    myWind.document.write(
      `<img src="${state.certificateUrl}" height="100%" style="margin-left:20px;"/>`
    );
    setTimeout(() => {
      myWind.print();
      myWind.close();
    }, 500);
  }
  const profileImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
  console.log(state);
  useEffect(() => {
    if (state === null) {
      navigate("/verify/e_certificate/");
    }
  }, []);
  return (
    <div className="cert-view-body">
      <div class="oa-logo">
        <a href="/verify/e_certificate">
          <img src={oalogo} alt="logo" />
        </a>
      </div>
      {state.studentName != null && (
        <div className="student-info-div">
          <div className="profile-main">
            <div className="profile-image">
              <img
                src={state.studentProfile ? state.studentProfile : profileImage}
                alt=""
              />
            </div>
            <p className="st_name">{state.studentName}</p>
            <p className="email">{state.studentId}</p>
          </div>
          <div className="hr"></div>
          <div className="cert-content">
            <h3 className="cont-header">Course Completed </h3>
            <p className="cont-value">{state.courseCompleted}</p>
          </div>
          <div className="hr"></div>
          <div className="cert-content">
            <h3 className="cont-header">Certificate Earned at</h3>
            <p className="cont-value">{state.certificateEarned}</p>
          </div>
          <div className="hr"></div>
          <div className="cert-content">
            <h3 className="cont-header">Learned Skills</h3>
            <p className="cont-value">
              {state.learnedSkills.map((item, index) => {
                return (
                  <span key={index} className="skills-chip">
                    {item}
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      )}

      <div className="cert-card-div">
        <img src={state.certificateUrl} ref={certRef} alt="Certificate" />
        <a href={state.certificateUrl} download={state.certificateUrl}>
          Download
        </a>

        {/* <button onClick={onCertDowload}>Download</button> */}
      </div>
    </div>
  );
}
