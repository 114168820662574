import React, { useEffect } from "react";

import { Appbar } from "../Appbar/Appbar";
import axios from "../../index";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CollectionCard from "./Widgets/CollectionCard";

function MCQ() {
  const [allCollection, setAllCollection] = useState([]);
  const navigate = useNavigate();

  function getAllCollections() {
    axios
      .get("/mcq/get/collections")
      .then((res) => {
        setAllCollection(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  useEffect(() => {
    getAllCollections();
    return () => console.log("useEffect cleanup");
  }, []);
  return (
    <>
      <Appbar />
      <div className="all-collection">
        {allCollection.map((item, index) => {
          return (
            <CollectionCard
              key={index}
              bgColor={item.bgColor}
              collectionId={item._id}
              languageImage={item.languageImage}
              languageName={item.languageName}
            />
          );
        })}
      </div>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     allCollection: state.allCollection,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCollection: () => dispatch(getCollection()),
//   };
// };

export default MCQ;
