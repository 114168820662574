import React, { useEffect, useState } from "react";
import "./Style/FooterStyle.scss";
import { FiFacebook, FiLinkedin, FiInstagram, FiYoutube } from "react-icons/fi";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "../../index";
import { Link } from "react-router-dom";
import { scrollPosition } from "../Functions/ScrollPosition";

export function Footer() {
  const emailPattan = /^\w+([\.-]?\w)*@\w+([\.-]?\w*)*(\.[a-z\D]{2,3})$/g;

  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [validation, setValidation] = useState(true);

  function subscribe() {
    if (emailPattan.test(subscribeEmail)) {
      axios
        .post("/subscribed/user/add/", { email: subscribeEmail })
        .then((res) => {
          console.log(res.data);
          setSubscribeEmail("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setValidation(false);
    }
  }

  useEffect(() => {
    AOS.init({
      duration: "2s",
    });
  }, []);

  return (
    <>
      <footer>
        <main>
          <section
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-offset="50"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500"
            className="girdItem item1"
          >
            <h1>Ocean Academy</h1>
            <p>
              Ocean was founded on the principle of building and implementing
              great ideas that drive progress for the students and clients.
            </p>
            <div className="row">
              <input
                id="textfield"
                type="email"
                placeholder="Enter Your Email"
                value={subscribeEmail}
                required
                onChange={(e) => {
                  setSubscribeEmail(e.target.value);
                  setValidation(true);
                }}
                style={{
                  border: validation ? "2px solid white" : "2px solid red",
                }}
              />
              <div
                className="subcriptionBtn"
                style={{ color: "rgb(0, 174, 255)" }}
                onClick={subscribe}
              >
                Subscribe
              </div>
            </div>
          </section>
          <section
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-offset="50"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500"
            className="girdItem item2"
          >
            <p>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="tel:+914132240580"
              >
                0413-2240580
              </a>
            </p>
            <p>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:oceanacademy@gmail.com"
              >
                oceanacademy@gmail.com
              </a>
            </p>
          </section>
          <section
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-offset="50"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500"
            className="girdItem item3"
          >
            <Link
              to="/contactus"
              style={{ color: "white", textDecoration: "none" }}
            >
              <p onClick={scrollPosition}>CONTACT US</p>
            </Link>
            <Link
              to="/services"
              style={{ color: "white", textDecoration: "none" }}
            >
              <p onClick={scrollPosition}>SERVICES</p>
            </Link>
            <Link
              to="/onlinecourses"
              style={{ color: "white", textDecoration: "none" }}
            >
              <p onClick={scrollPosition}>ONLINE COURSES</p>
            </Link>
            <Link
              to="/offlinecourses"
              style={{ color: "white", textDecoration: "none" }}
            >
              <p onClick={scrollPosition}>OFFLINE COURSES</p>
            </Link>

            {/* <Link to="help" style={{ color: "white", textDecoration: "none" }}>
              <p>HELP</p>
            </Link> */}
            {/* <Link to="faq" style={{ color: "white", textDecoration: "none" }}>
              <p>FAQ</p>
            </Link> */}
          </section>

          <section
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-offset="50"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500"
            className="girdItem item4"
          >
            <Link
              to="/terms-conditions"
              style={{ color: "white", textDecoration: "none" }}
            >
              <p onClick={scrollPosition}>TERMS AND CONDITIONS</p>
            </Link>
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/privacy-policy"
            >
              <p onClick={scrollPosition}>PRIVACY POLICIES</p>
            </Link>
            {/* <p>WORK WITH US</p> */}
            <Link
              to="/aboutus"
              style={{ color: "white", textDecoration: "none" }}
            >
              <p onClick={scrollPosition}>ABOUT US</p>
            </Link>
          </section>
        </main>
      </footer>

      <section className="iconbar">
        <a
          href="https://www.facebook.com/oceanacademy.co.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiFacebook className="icons" />
        </a>
        <a
          href="https://www.instagram.com/oceanacademy_official"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiInstagram className="icons" />
        </a>
        <a
          href="https://youtube.com/@ocean_academy?si=QRp7WqgCbwfq-hIz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiYoutube className="icons" />
        </a>
        <a
          href="https://www.linkedin.com/company/oceanacademyedu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiLinkedin className="icons" />
        </a>
      </section>
    </>
  );
}
