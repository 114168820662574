import React, { useState } from "react";
import "../styles/submit_alert.scss";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux/es/exports";
import axios from "../../../index";
import { useNavigate } from "react-router-dom";

export default function SubmitAlert({ close, mcq }) {
  const resultInfo = useSelector((state) => state.mcq.resultInfo);
  const mcqData = useSelector((state) => state.mcq);
  const navigate = useNavigate();

  const mark = Math.floor(
    (resultInfo.correct / resultInfo.totalQuestion) * 100
  );

  const [inputData, setInputData] = useState({ email: "", username: "" });
  const [validate, setValidate] = useState({ email: true, username: true });
  // const [resultsInfo, setResultInfo] = useState({
  //   correct: 0,
  //   wrong: 0,
  //   skiped: 0,
  // });

  function inputHandler(e) {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setValidate({ ...validate, [name]: true });
  }

  function checkEmail() {
    const filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/;
    if (!filter.test(inputData.email)) {
      setValidate({ ...validate, email: false });
      return false;
    }
    return true;
  }
  function validateName() {
    const filter = /^[A-Za-z\s]{3,}$/;
    if (!filter.test(inputData.username)) {
      setValidate({ ...validate, username: false });
      return false;
    }
    return true;
  }

  function getResult() {
    const bodyData = {
      ...resultInfo,
      answerKey: mcq,
      username: inputData.username,
      email: inputData.email,
    };
    console.log(bodyData);
    if (checkEmail() && validateName()) {
      axios
        .post("/mcq/question/result", bodyData)
        .then((res) => {
          close();
          navigate("/mcq", { replace: true });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const invalidInput = {
    invalid: { x: [0, 12, -12, 12, -12, 0], borderColor: "rgb(234, 58, 49)" },
    valid: { x: 0 },
  };

  return (
    <div className="submit-alert-div">
      <motion.div whileHover={{ scale: 1.2 }} className="ico" onClick={close}>
        <IoMdClose />
      </motion.div>
      <div className="result-content">
        <div className="collection-name">{mcqData.collection}</div>
        <div className="topic-name">{resultInfo.topic}</div>
        <div></div>
        <div className="progress">
          <div className="result-info">
            <div className="status">
              <div className="correct">{resultInfo.correct}</div>
              <div className="wrong">{resultInfo.wrong}</div>
              <div className="skiped">{resultInfo.skiped}</div>
            </div>
            <span>{`${mark}%`}</span>
          </div>
          <div className="bg-prog">
            <motion.div
              className="prog"
              initial={{ width: 0 }}
              animate={{
                width: `${mark}%`,
                transitionDuration: 1,
              }}
              transition={{ delay: 1 }}
            ></motion.div>
          </div>
        </div>
        <div className="info">
          Note: We need an email address to send you your results.
        </div>
        <motion.div
          variants={invalidInput}
          animate={validate.username ? "valid" : "invalid"}
          transition={{ duration: 0.5 }}
          className="input"
        >
          <input
            type="text"
            placeholder="Name"
            name="username"
            value={inputData.username}
            onChange={inputHandler}
          />
        </motion.div>
        <motion.div
          variants={invalidInput}
          animate={validate.email ? "valid" : "invalid"}
          transition={{ duration: 0.5 }}
          className="input"
        >
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={inputData.email}
            onChange={inputHandler}
          />
        </motion.div>
        <motion.div
          className="submit-btn"
          animate={{ background: "#1975ca" }}
          whileHover={{ background: "#0f5ea8" }}
          whileTap={{
            scale: 0.95,
          }}
          onClick={getResult}
        >
          Get Result
        </motion.div>
      </div>
    </div>
  );
}
