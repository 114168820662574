import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./zoomPage.scss";

export default function ZoomPage() {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const state = useLocation().state;

  // function iframeAccess() {
  //   let fr = document.querySelector(".i-f");
  //   let iframe = iframeRef.current;
  //   let iframeWindow = iframe.contentWindow;
  //   console.log(iframeWindow);
  //   console.log(fr.contentWindow);
  // }
  return (
    <div className="zoom-div">
      <div className="zoomPage">
        <button
          className="btn"
          onClick={() => {
            navigate("/dashboard/mycourses");
          }}
        >
          Back
        </button>

        {/* <button onClick={iframeAccess}>Full Access</button> */}
        <div className="zoom-page-div">
          <iframe
            ref={iframeRef}
            id="i-f"
            className="i-f"
            src={state.zoomLink}
            title="zoom"
            allow="microphone; camera"
            allowfullscreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
