import React from "react";
import "../styles/topic_Card.scss";
import { HiChevronRight } from "react-icons/hi";
import { useState } from "react";
import { motion } from "framer-motion";

export default function TopicCard({ topic, onClick }) {
  const rand = Math.floor(Math.random() * 360) + 1;
  return (
    <div
      transition={{ duration: 0.1 }}
      className="topic-card"
      style={{ background: `hsl(${rand}, 100%, 95%)` }}
      onClick={onClick}
      onMouseOver={(e) => {
        e.currentTarget.style.background = `hsl(${rand}, 100%, 90%)`;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = `hsl(${rand}, 100%, 95%)`;
      }}
    >
      <div className="content">
        <div
          className="avatar"
          style={{ background: `hsl(${rand}, 80%, 75%)` }}
        >
          <span style={{ color: `hsl(${rand}, 80%, 40%)` }}>
            {topic ? topic[0] : "?"}
          </span>
        </div>
        <div className="topic" style={{ color: `hsl(${rand}, 80%, 40%)` }}>
          {topic}
        </div>
      </div>
      <div className="ico" style={{ color: `hsl(${rand}, 80%, 40%)` }}>
        <HiChevronRight />
      </div>
    </div>
  );
}
