import { createStore, applyMiddleware } from "redux";
// import { configureStore, applyMiddleware } from "@reduxjs/toolkit";

import { composeWithDevTools } from "@redux-devtools/extension";

import rootReducer from "./rootReducer";
// import logger from "redux-logger";

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: () => applyMiddleware(logger),
// });
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware()));

export default store;
