import React, { useState, useEffect } from "react";
import "../chatbot/chatbot.scss";

const ChatbotSecond = () => {
  const displayChat = () => {
    const doc = window.document;
    const chatbot = doc.querySelector(".chatbot-container");
    console.log(chatbot, "chatbot----");
    chatbot.classList.toggle("chatbot-container-active");
  };

  return (
    <div>
      <div className="message-div-con">
        <img
          id="chatbot-image"
          src={require("../chatbot/images/chatbot-image.svg").default}
          alt="Chat Line"
          width={65}
          height={50}
          onClick={() => displayChat()}
        />
      </div>
    </div>
  );
};

export default ChatbotSecond;
