import axios from "../../index";
import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { chooseAnswer, getQuestion } from "../../redux/redux_index";
import { useEffect } from "react";
import { useState } from "react";
import Question from "./Widgets/Question";
import CustomButton from "./Widgets/CustomButton";

import Modal from "./Widgets/Modal";
import { AnimatePresence } from "framer-motion";
import { setResult } from "../../redux/MCQ/MCQAction";

export default function MCQQuestions() {
  const questions = useSelector((state) => state.mcq.questions);
  const [mcq, setMcq] = useState(questions);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const param = useParams();

  function getQuestions() {
    axios
      .post("/mcq/question", {
        collectionId: param.collectionId,
        topic: param.topic,
      })
      .then((res) => {
        dispatch(getQuestion(res.data));
        setMcq(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  function selectOption(qIndex, optionValue) {
    dispatch(chooseAnswer(qIndex, optionValue));
    setMcq(
      mcq.map((item, index) => {
        if (index === qIndex) {
          return { ...item, selectedOption: optionValue };
        }
        return item;
      })
    );
  }
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  function onSubmit() {
    const resultsInfo = {
      correct: 0,
      wrong: 0,
      skiped: 0,
      collectionId: "",
      totalQuestion: mcq.length,
      topic: "",
    };

    for (let qus of mcq) {
      resultsInfo.collectionId = qus.collectionId;
      resultsInfo.topic = qus.topic;
      if (qus.selectedOption) {
        if (qus.answer === qus.selectedOption) {
          resultsInfo.correct = resultsInfo.correct + 1;

          // setResultInfo({ ...resultsInfo, correct: resultsInfo.correct + 1 });
        } else {
          // setResultInfo({ ...resultsInfo, wrong: resultsInfo.wrong + 1 });
          resultsInfo.wrong = resultsInfo.wrong + 1;
        }
      } else {
        // setResultInfo({ ...resultsInfo, skiped: resultsInfo.skiped + 1 });
        resultsInfo.skiped = resultsInfo.skiped + 1;
      }
    }
    dispatch(setResult(resultsInfo));
    modalOpen ? close() : open();
  }
  useEffect(() => {
    getQuestions();
  }, []);
  return (
    <div>
      <div className="questions">
        {mcq.map((q, index) => {
          return (
            <Question
              key={index}
              options={q.options}
              questionDescription={q.questionDescription}
              question={q.question}
              isResult={false}
              isSelected={q.selectedOption}
              selectOption={selectOption}
              questionIndex={index}
            />
          );
        })}
      </div>
      <CustomButton onClick={onSubmit}>Submit</CustomButton>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalOpen && (
          <Modal modalOpen={modalOpen} handleClose={close} mcq={mcq} />
        )}
      </AnimatePresence>
    </div>
  );
}
