import "./Style/RegisterStyle.scss";
import { AppbarCombo } from "../AppbarCombo/AppbarCombo";
import axios from "../../index";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import firebaseStorage from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export function Register() {
  const emailPattan = /^\w+([\.-]?\w)*@\w+([\.-]?\w*)*(\.[a-z\D]{2,3})$/g;
  const stringPattan = /^([a-zA-Z]{3})\s?([a-zA-Z]*)$/;
  const optionalTextPattan = /^([a-zA-Z]*)$/;
  const param = useParams();
  const navigate = useNavigate();
  const [uploadPercent, setUploadPercent] = useState("Upload");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    occupation: "student",
    instOrOrg: "",
    state: "Pondicherry",
    country: "india",
    mobileNumber: param.mobileNumber,
    profilePicture:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
    skills: [],
  });
  const [validation, setValidation] = useState({
    firstName: true,
    dateOfBirth: true,
    email: true,
    state: true,
    country: true,
    mobileNumber: true,
    lastName: true,
    instOrOrg: true,
  });
  function finalValidation() {
    let isValid = true;
    let email = true;
    let firstName = true;
    let dateOfBirth = true;
    let state = true;
    let country = true;
    let mobileNumber = true;
    let lastName = true;
    let instOrOrg = true;

    if (!emailPattan.test(userData.email)) {
      email = false;
      isValid = false;
    }

    if (!stringPattan.test(userData.firstName)) {
      firstName = false;
      isValid = false;
    }
    if (!optionalTextPattan.test(userData.lastName)) {
      lastName = false;
      isValid = false;
    }
    if (!optionalTextPattan.test(userData.instOrOrg)) {
      instOrOrg = false;
      isValid = false;
    }

    if (!stringPattan.test(userData.state)) {
      state = false;
      isValid = false;
    }

    if (!stringPattan.test(userData.country)) {
      country = false;
      isValid = false;
    }

    if (!userData.mobileNumber.length === 10) {
      mobileNumber = false;
      isValid = false;
    }

    if (userData.dateOfBirth === "") {
      dateOfBirth = false;
      isValid = false;
    }

    setValidation({
      email: email,
      dateOfBirth: dateOfBirth,
      firstName: firstName,
      state: state,
      country: country,
      mobileNumber: mobileNumber,
      lastName: lastName,
      instOrOrg: instOrOrg,
    });

    return isValid;
  }

  function onchangeHandler(e) {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      uploadImage(files[0]);
    } else if (name === "dateOfBirth") {
      console.log(value, typeof value);
      let date = new Date();
      console.log(date.toUTCString());
      setUserData({ ...userData, [name]: value });
      setValidation({ ...validation, [name]: true });
    } else {
      setUserData({ ...userData, [name]: value });

      setValidation({ ...validation, [name]: true });
    }
  }

  async function submit() {
    if (!finalValidation()) {
      alert("form invalid");
    } else {
      axios
        .post("user/create", userData)
        .then((res) => {
          // navigate('/class_room')
          return res.data;
        })
        .then(async (res) => {
          await axios
            .post("user/login", { mobileNumber: res.mobileNumber })
            .then((res) => {
              localStorage.setItem("token", res.data.token);
              navigate("/dashboard/mycourses");
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }

  function fileUpload() {
    const fileInp = document.querySelector("#profile-inp");
    fileInp.type = "file";
    fileInp.accept = "image/*";
    fileInp.style.display = "none";
    fileInp.click();
  }

  function uploadImage(img) {
    setUserData({ ...userData, profilePicture: null });
    console.log(img.name);
    const storageRef = ref(firebaseStorage, "user/" + img.name);
    const uploadTask = uploadBytesResumable(storageRef, img);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadPercent(progress.toFixed(0) + "%");
        // console.log("Upload is " + progress.toFixed(0) + "% done");
        // console.log(snapshot.state);
      },
      (error) => {
        console.log(error);
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          setUserData({ ...userData, profilePicture: downloadURL });
        });
      }
    );
  }
  return (
    <>
      <AppbarCombo />
      <section className="register">
        <div className="register-Card">
          <h1 className="title">Register Your Account</h1>

          <div className="upload-Img-Row">
            <div className="register-Card-Circle" onClick={fileUpload}>
              <input
                type="hidden"
                id="profile-inp"
                name="profilePicture"
                onChange={onchangeHandler}
              />
              {userData.profilePicture !== null ? (
                <img src={userData.profilePicture} alt="profile" />
              ) : (
                <div className="upload">{uploadPercent}</div>
              )}
            </div>
            <div className="upload-Btn" onClick={fileUpload}>
              Upload
            </div>
          </div>
          <div className="register-Card-Row">
            <div className="register-Card-Textfield">
              <p>First Name</p>
              <input
                type="text"
                name="firstName"
                value={userData.firstName}
                onChange={onchangeHandler}
              />
              {!validation.firstName && (
                <p style={{ color: "red" }}>Invalid name</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>Last Name</p>
              <input
                type="text"
                name="lastName"
                value={userData.lastName}
                onChange={onchangeHandler}
              />
              {!validation.lastName && (
                <p style={{ color: "red" }}>Invalid lastname</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>Gender</p>
              <select name="gender" onChange={onchangeHandler}>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="register-Card-Textfield">
              <p>Date of Birth</p>
              <input
                type="date"
                name="dateOfBirth"
                value={userData.dateOfBirth}
                onChange={onchangeHandler}
              />
              {!validation.dateOfBirth && (
                <p style={{ color: "red" }}>Invalid Date of Birth</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>E-mail Address</p>
              <input
                type="text"
                name="email"
                value={userData.email}
                onChange={onchangeHandler}
              />
              {!validation.email && (
                <p style={{ color: "red" }}>Invalid Email</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>Occupation</p>
              <select name="occupation" onChange={onchangeHandler}>
                <option value="student">Student</option>
                <option value="working">WorkingPro</option>
              </select>
            </div>
            <div className="register-Card-Textfield">
              <p>Institution</p>
              <input
                type="text"
                name="instOrOrg"
                value={userData.instOrOrg}
                onChange={onchangeHandler}
              />
              {!validation.instOrOrg && (
                <p style={{ color: "red" }}>Invalid instOrOrg</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>Country</p>
              <input
                type="text"
                name="country"
                value={userData.country}
                onChange={onchangeHandler}
              />
              {!validation.country && (
                <p style={{ color: "red" }}>Invalid country</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>State</p>
              <input
                type="text"
                name="state"
                value={userData.state}
                onChange={onchangeHandler}
              />
              {!validation.state && (
                <p style={{ color: "red" }}>Invalid state</p>
              )}
            </div>
            <div className="register-Card-Textfield">
              <p>Phone Number</p>
              <input
                readOnly={true}
                type="text"
                name="mobileNumber"
                value={userData.mobileNumber}
                onChange={onchangeHandler}
              />
              {!validation.mobileNumber && (
                <p style={{ color: "red" }}>Invalid mobile number</p>
              )}
            </div>
          </div>
          <br />
          <div className="register-Card-Btn" onClick={submit}>
            Submit
          </div>
        </div>
      </section>
    </>
  );
}
