import React from "react";
import "./Style/FloatingActionButton.scss";
import WhatsappBtn from "./WhatsappBtn";
import CompilerBtn from "./CompilerBtn";
import E_certificateBtn from "./E_certificateBtn";
import ChatbotSecond from "./secondChatbotBtn";

export function FloatingActionButton() {
  return (
    <div className="f-a-b">
      <WhatsappBtn />
      <CompilerBtn />
      <E_certificateBtn />
      <ChatbotSecond />
    </div>
  );
}
