import axios from "../../index";

const KEY = "rzp_test_V5lYmgTFReqwlV";
// eslint-disable-next-line react-hooks/rules-of-hooks
// let Razorpay = useRazorpay();
async function createOrder(amount) {
  return axios
    .post("/payment/order", {
      amount: amount * 100,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

async function handlePayment(
  Razorpay,
  amount,
  {
    successCallback = () => {
      console.log("calback not assign");
    },
    courseInfo = {},
    userInfo = {},
  }
) {
  const order = await createOrder(amount);
  console.log(order);
  var options = {
    key: KEY,
    amount: order.amount,
    currency: order.currency,
    name: "Ocean Academy",
    description: "Pay & Checkout this " + courseInfo.courseName + " course",
    image:
      "https://firebasestorage.googleapis.com/v0/b/oceanlivereact.appspot.com/o/oa.png?alt=media&token=0751a9a2-72c8-43c4-8d29-45a2a0b9e32f",
    order_id: order.order_id,
    handler: function (response) {
      console.log(response);
      alert("This step of Payment Succeeded");
      successCallback();
    },

    prefill: {
      //Here we are prefilling random contact
      contact: userInfo.mobileNumber,
      //name and email id, so while checkout
      name: userInfo.firstName,
      email: userInfo.email,
    },
    notes: {
      description: "Best Course for SDE placements",
      language: "Available in 4 major Languages JAVA,C/C++, Python, Javascript",
      access: "This course have Lifetime Access",
    },
    theme: {
      color: "#01a0f6",
    },
  };
  const rzp1 = new Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    alert(response.error.code);
    console.log(response.error.code);
    alert(response.error.description);
    console.log(response.error.description);
    alert(response.error.source);
    console.log(response.error.source);
    alert(response.error.step);
    console.log(response.error.step);
    alert(response.error.reason);
    console.log(response.error.reason);
    alert(response.error.metadata.order_id);
    console.log(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
    console.log(response.error.metadata.payment_id);
  });

  rzp1.open();
}

export default handlePayment;
