import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../chatbot/chatbot.scss";
import data from "./data/questionsAndAnswers.json";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Display the initial message when the component mounts
    const initialMessage = data.find((item) => item._id === "1");
    // Set initial messages based on the initial message found
    if (initialMessage) {
      const initialMessages = [
        {
          text: initialMessage.question,
          sender: "bot",
          options: initialMessage.answer.map((ans) => ans.course),
        },
      ];
      setMessages(initialMessages);
    }
  }, [data]);

  // for shake effect

  const handleOptionClick = (option) => {
    const question = data.find(
      (item) => item.question.toLowerCase() === option.toLowerCase()
    );
    if (question) {
      const botResponse = {
        text: question.headingAnswer,
        options: question.answer.map((answer) => answer.course),
      };
      const newMessages = [
        ...messages,
        { text: option, sender: "user" },
        {
          text: botResponse.text,
          sender: "bot",
          options: botResponse.options,
        },
      ];
      setMessages(newMessages);
    }
  };


  const closeChat = () => {
    console.log("working...");
    const doc = window.document;
    const chatbot = doc.querySelector(".chatbot-container");
    console.log(chatbot);
    chatbot.classList.toggle("chatbot-container-active");
  };

  return (
    // header
    <div className="chatbot-container">
      <div className="header-container">
        <img
          src="https://oa-admin-sms.web.app/assets/logo.618c06e296f2c77bcfa3127acf2001c0.svg"
          width={250} className="oa_logo"
        ></img>
        <img
          className="close-icon"
          src={require("./images/1cancel_svgrepo.com.svg").default}
          alt="Chat Line"
          onClick={() => closeChat()}
        />
      </div>

      {/* content */}
      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <>
            <div
              key={index}
              className={`message ${message.sender}`}
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
              }}
            >
              {message.sender === "bot" && (
                <div style={{ marginRight: "10px" }}>
                  <img
                    src={require("./images/profile.svg").default}
                    alt="Bot Profile"
                    className="profile-image"
                    width={30}
                  />
                </div>
              )}
              <div className="message-content" style={{ flex: 1 }}>
                <span>{message.text}</span>
                {message.options && message.sender === "bot" && (
                  <div className="main-select-btn">
                    {message.options.map((option, i) => (
                      <button
                        className="select-button"
                        key={i}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              {message.sender === "user" && (
                <div style={{ marginLeft: "10px" }}>
                  <img
                    src={require("./images/tick.svg").default}
                    alt="User Profile"
                    className="tick-image"
                    width={20}
                  />
                </div>
              )}
            </div>
          </>
        ))}
      </div>

      {/* footer */}
      <div className="footer-container">
        <div className="inner-footer">
          <img
            src={require("./images/phone_svgrepo.com.svg").default}
            alt="Chat Line"
            width={30}
            className="call-option"
          />
          {" 0413-2240580 "}
        </div>

        <div className="inner-footer">
          <img
            src={require("./images/email_svgrepo.com.svg").default}
            alt="Chat Line"
            width={30}
            className="call-option"
          />
          {" oceanacademy@gmail.com"}
        </div>
      </div>
    </div>
  );
};

Chatbot.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      headingAnswer: PropTypes.string.isRequired,
      answer: PropTypes.arrayOf(
        PropTypes.shape({
          course: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default Chatbot;
