import React, { useState } from "react";
import "./Style/OfflineDialogStyle.scss";
import offlineDialog from "../../../Image/offlineDialog .svg";
import { IoCloseOutline } from "react-icons/io5";
import { HiDownload } from "react-icons/hi";
import axios from "../../../../index";

export function OfflineDialog(props) {
  const emailPattan = /^\w+([\.-]?\w)*@\w+([\.-]?\w*)*(\.[a-z\D]{2,3})$/gi;
  const numberPattan = /^\d{10}$/;
  const stringPattan = /^([a-zA-Z]{3})\s?([a-zA-Z]*)$/;

  const [userInfo, setUserInfo] = useState({
    name: "",
    mobileNumber: "",
    email: "",
  });
  function inputHandler(e) {
    const { value, name } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
    setValidation({ ...validation, [name]: value });
  }
  const [validation, setValidation] = useState({
    name: true,
    mobileNumber: true,
    email: true,
  });

  function finalValidation() {
    let isValid = true;
    let emailMatch = !emailPattan.test(userInfo.email);
    if (emailMatch) {
      console.log("wrong match");
      setValidation({ ...validation, email: false });
      isValid = false;
    }
    if (!numberPattan.test(userInfo.mobileNumber)) {
      setValidation({ ...validation, mobileNumber: false });
      isValid = false;
    }
    if (!stringPattan.test(userInfo.name)) {
      setValidation({ ...validation, name: false });
      isValid = false;
    }

    return isValid;
  }

  function submit() {
    if (!finalValidation()) {
      // alert("invalid form");
      console.log("invalid");
    } else {
      // console.log({ ...userInfo, courseId: props.courseId });
      axios
        .post("/downloaded/course/add/user/", {
          ...userInfo,
          courseId: props.courseId,
        })
        .then((res) => {
          console.log(res.data);
          setUserInfo({
            name: "",
            mobileNumber: "",
            email: "",
          });
          closeDialog();
          window.open(props.downloadLink);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const closeDialog = () => {
    document.querySelector(".offlineDialog").style.display = "none";
  };
  return (
    <div className="offlineDialog">
      <div className="card">
        <div className="closeBtn">
          <p>Download PDF</p>
          <IoCloseOutline className="closeIcon" onClick={closeDialog} />
        </div>
        <div className="row">
          <img src={offlineDialog} alt="" />

          <div className="col">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={userInfo.name}
              required
              onChange={inputHandler}
            />
            {!validation.name && (
              <span style={{ color: "red" }}>Invalid Name</span>
            )}
            <input
              type="number"
              placeholder="Mobile Number"
              name="mobileNumber"
              value={userInfo.mobileNumber}
              required
              onChange={inputHandler}
            />
            {!validation.mobileNumber && (
              <span style={{ color: "red" }}>Invalid Mobile Number</span>
            )}
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={userInfo.email}
              required
              onChange={inputHandler}
            />
            {!validation.email && (
              <span style={{ color: "red" }}>Invalid Email</span>
            )}
            <br /> <br />
            <div className="btn">
              <div className="left"></div>
              <div className="right"></div>
              <div className="top"></div>
              <div className="bottom"></div>
              <div className="btnContent" onClick={submit}>
                <HiDownload className="icon" />
                <p>Download Pdf</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
