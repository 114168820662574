import React, { useEffect } from "react";
import "./Style/WhatsNewAtOcean.scss";
import Whats_New from "../../Image/whats_new.png";
import AOS from "aos";
import "aos/dist/aos.css";

export function WhatsNewAtOcean() {
  useEffect(() => {
    AOS.init({
      // duration: 1000,
    });
  }, []);

  return (
    <div className="whatsNewAtOcean">
      <h1
        data-aos="fade-left"
        data-aos-offset="100"
        data-aos-duration="500"
        // data-aos-easing="ease-in-sine"
      >
        What's New at Ocean Academy
      </h1>
      <p
        data-aos="fade-left"
        data-aos-offset="100"
        data-aos-duration="500"
        // data-aos-easing="ease-in-sine"
      >
        We are proud to release our most innovative learning platform to learn
        cutting-edge technologies. Here's what's coming up next for Ocean
        Academy Live Members.
      </p>
      <div className="row">
        <div className="col">
          <h1
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="500"
            // data-aos-easing="ease-in-sine"
          >
            Ocean Academy Launches its own LMS for our OA-Live Learners.
          </h1>
          <p
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="500"
            // data-aos-easing="ease-in-sine"
          >
            Ocean Academy Launches its own LMS for our OA-Live Learners. OA has
            launched its own CMS for our learners to take the online class and
            enroll in the new online batch effortlessly. This app will be more
            helpful for online learners to get the class schedule details.
          </p>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="col"
        >
          <img src={Whats_New} alt="" />
        </div>
      </div>
    </div>
  );
}
