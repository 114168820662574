import React from "react";
import { HiCode } from "react-icons/hi";

export default function CompilerBtn() {
  return (
    <div className="fab-name">
      <div className="tagname" style={{ maxWidth: 120 }}>
        <span>Code Editor</span>
      </div>
      <a
        href={"https://oa-compiller.web.app/"}
        target="blank"
        className="floatingActionButton"
      >
        <HiCode className="icon" />
      </a>
    </div>
  );
}
