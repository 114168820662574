import React from "react";
import { motion } from "framer-motion";
import "./alert_text_field.scss";

export default function AlertTextField(props) {
  return (
    <motion.div
      animate={{
        x: props.isNotValid ? [-50, 50, -50, 50, -50, 50, 0] : 0,
      }}
      transition={{
        duration: 0.5,
      }}
      className={`inp-div t-a ${props.isNotValid && "inValidFild"}`}
    >
      {props.isMobileNum && props.value > 0 && (
        <span className="mobNum">+91&nbsp;</span>
      )}
      {props.type !== "textarea" ? (
        <input
          className={props.isMobileNum && props.value > 0 && "isNum"}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
      ) : (
        <textarea
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          rows={
            props.value.split("\n").length < 5
              ? props.value.split("\n").length
              : 4
          }
        ></textarea>
      )}
      <div className={`ico `}>{props.icon}</div>
    </motion.div>
  );
}
