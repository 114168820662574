import React from "react";
import { AppbarCombo } from ".././AppbarCombo/AppbarCombo";
import { SideNavbar } from "../SideNavbar/SideNavbar";
import { closeSideNavbar } from "../Functions/SidebarFunction";
import "./Style/CareerStyle.scss";
import Team from "../Image/Carreer/team.png";
import { useState } from "react";

export function Career() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsvalidEmail] = useState(true);
  function submit() {
    const emailPattan = /^\w+([\.-]?\w)*@\w+([\.-]?\w*)*(\.[a-z\D]{2,3})$/gi;
    let isV = emailPattan.test(email);
    setIsvalidEmail(isV);
    if (isV) {
      console.log("subscribe successfully...");
      setEmail("");
    }
  }
  function onchangeHandler(e) {
    setIsvalidEmail(true);
    setEmail(e.target.value);
  }
  return (
    <>
      <SideNavbar />
      <AppbarCombo />
      <div onClick={closeSideNavbar} className="career">
        <div className="career-row">
          <div className="career-div-left">
            <p>We are Coming With Something</p>
            <h1>AMAZING</h1>
            <p>Subscribe And Stay Updated</p>
            <br /> <br />
            <div className="career-input-row">
              <input
                type="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={onchangeHandler}
              />

              <div
                className="btn"
                onClick={() => {
                  submit();
                }}
              >
                Subscribe
              </div>
            </div>
            {!isValidEmail && (
              <span style={{ color: "red" }}>Invalid Email</span>
            )}
          </div>

          <img id="career-div-right" src={Team} alt="" />
        </div>
        <div className="left-box"></div>
        <div className="fixed">
          <div className="right-sm"></div>
          <div className="right-lg"></div>
        </div>
      </div>
    </>
  );
}
